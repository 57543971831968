import { union } from "lodash";

import { Results } from "@/store/modules/filterStore/types";

const orFilter = (results: Results, activeFilters: string[]) => {
    if (!activeFilters.length) return results;

    const resultsByTerm: Results[] = [];

    activeFilters.forEach((term) => {
        resultsByTerm.push(results.filter((result) => result.filterterms.indexOf(term) > -1));
    });

    return union(...resultsByTerm);
};

export default orFilter;
