import { ActionContext } from "vuex";
import { ResultItem, Results } from "./types";
import { FilterState, state } from "./state";
import { getResultGroups } from "../../helpers/getResultGroups";
import { sortResults } from "../../helpers/sortResults";
import andFilter from "../../helpers/andFilter";
import orFilter from "../../helpers/orFilter";
import getResultGroupsIntersection from "../../helpers/getResultGroupsIntersection";

export const actions: any = {
    setFilterInitialized({ commit }: ActionContext<FilterState, FilterState>, isInitialized: boolean) {
        commit("SET_FILTER_INITIALIZED", isInitialized);
    },

    updateSearchTerm({ commit, dispatch }: ActionContext<FilterState, FilterState>, searchTerm: string) {
        commit("SET_SEARCH_TERM", searchTerm);
        dispatch("filterResults");
    },

    clearSearchTerm({ dispatch }: ActionContext<FilterState, FilterState>) {
        dispatch("updateSearchTerm", "");
    },

    setFilterTeasersLoaded({ commit }: ActionContext<FilterState, FilterState>, loaded: boolean) {
        commit("SET_FILTER_TEASERS_LOADED", loaded);
    },

    setFilterTeaserCount({ commit }: ActionContext<FilterState, FilterState>, count: number) {
        commit("SET_FILTER_TEASER_COUNT", count);
    },

    setLoadedFilterTeasers({ commit }: ActionContext<FilterState, FilterState>, count: number) {
        commit("SET_LOADED_FILTER_TEASERS", count);
    },

    setAvailableResults({ commit }: ActionContext<FilterState, FilterState>, results: Results) {
        commit("SET_AVAILABLE_RESULTS", results);
    },

    setFilteredResults({ commit }: ActionContext<FilterState, FilterState>, filteredResults: Results) {
        commit("SET_FILTERED_RESULTS", filteredResults);
    },

    setAvailableFilterGroups({ commit }: ActionContext<FilterState, FilterState>, filterGroups: object) {
        commit("SET_AVAILABLE_FILTER_GROUPS", filterGroups);
    },

    setActiveFilters({ commit }: ActionContext<FilterState, FilterState>, searchTerms: string[]) {
        commit("SET_ACTIVE_FILTERS", searchTerms);
    },

    setLoading({ commit }: ActionContext<FilterState, FilterState>, loading: boolean) {
        commit("SET_LOADING", loading);
    },

    initFilterTeaserGroups({ commit }: ActionContext<FilterState, FilterState>) {
        commit("SET_LOADED_FILTER_TEASERS", 0);
        commit("SET_FILTER_TEASERS_LOADED", false);
    },

    sortResultsIntoGroups({ state, getters, commit }: ActionContext<FilterState, FilterState>): Promise<void> {
        return new Promise((resolve) => {
            const filteredResults = state.availableResults.filter((result: ResultItem) => {
                const resultHasTerm = (term: string) => result.filterterms.includes(term);

                return state.activeFilters.some(resultHasTerm);
            });

            //Create new empty resultGroups
            const totalResultGroups = getResultGroups(getters.getAvailableFilterGroups);

            filteredResults.forEach((result: ResultItem) => {
                result.filterterms.forEach((filterterm) => {
                    if (!totalResultGroups || !state.activeFilters.includes(filterterm)) return;

                    if (!totalResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].includes(result)) {
                        totalResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].push(result);
                    }
                });
            });

            commit("SET_TOTAL_RESULT_GROUPS", totalResultGroups);

            resolve();
        });
    },

    async sortAvailableResultsIntoGroups({ getters, commit }: ActionContext<FilterState, FilterState>): Promise<void> {
        return new Promise((resolve) => {
            //Create new empty resultGroups
            const availableResultGroups = getResultGroups(getters.getAvailableFilterGroups);

            getters.getAvailableResults.forEach((result: ResultItem) => {
                result.filterterms.forEach((filterterm) => {
                    if (!availableResultGroups || !getters.getGroupIdFromFilterTerm(filterterm)) return;

                    if (!availableResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].includes(result)) {
                        availableResultGroups[getters.getGroupIdFromFilterTerm(filterterm)].push(result);
                    }
                });
            });

            commit("SET_AVAILABLE_RESULT_GROUPS", availableResultGroups);

            resolve();
        });
    },

    orFilter({ getters, commit }: ActionContext<FilterState, FilterState>) {
        if (getters.searchTerm) {
            commit("SET_FILTERED_RESULTS", getters.getResultsWithSearchTerm);
        } else {
            commit("SET_FILTERED_RESULTS", getters.getIntersectionFromResultGroups);
        }
    },

    async orFilterResults({ dispatch }: ActionContext<FilterState, FilterState>) {
        await dispatch("sortResultsIntoGroups");

        await dispatch("orFilter");
    },

    /**
     * Filter results for each group, excluding the group itself for filtering
     * in order to calculate filter results for each filter excluding any filter within that group
     */
    hybridFilterResultsForFilterGroup({ getters, dispatch, commit }: ActionContext<FilterState, FilterState>) {
        const individualResultGroups = { ...getters.getAvailableResultGroups };

        for (const [groupId, resultGroup] of Object.entries(individualResultGroups)) {
            const individualFilters = [...getters.getActiveFilters].filter((filterTerm) => {
                return getters.getGroupIdFromFilterTerm(filterTerm) !== groupId;
            });

            individualResultGroups[groupId] = orFilter(resultGroup as Results, individualFilters);
        }

        commit("SET_FILTERED_GROUP_RESULTS", individualResultGroups);
    },

    async hybridFilterResults({ getters, dispatch, commit }: ActionContext<FilterState, FilterState>) {
        await dispatch("sortResultsIntoGroups");

        const filteredGroups = { ...getters.getTotalResultGroups };

        Object.values(filteredGroups).forEach((resultGroup) => {
            andFilter(resultGroup as Results, getters.getActiveFilters);
        });

        let filteredResults = getResultGroupsIntersection(Object.values(filteredGroups) as Results[]);

        if (getters.searchTerm) {
            filteredResults = getters.getResultsFilteredBySearchTerm(filteredResults);
        }

        dispatch("hybridFilterResultsForFilterGroup");

        commit("SET_FILTERED_RESULTS", filteredResults);
    },

    andFilterResults({ getters, commit }: ActionContext<FilterState, FilterState>) {
        let filteredResults = state.availableResults.filter((result: ResultItem) => {
            const resultHasTerm = (term: string) => result.filterterms.indexOf(term) > -1;

            return state.activeFilters.every(resultHasTerm);
        });

        if (getters.searchTerm) {
            filteredResults = getters.getResultsFilteredBySearchTerm(filteredResults);
        }

        commit("SET_FILTERED_RESULTS", filteredResults);
    },

    filterResults({ state, getters, commit, dispatch }: ActionContext<FilterState, FilterState>): Promise<void> {
        if (!(state.activeFilters && state.activeFilters.length)) {
            return new Promise((resolve) => {
                if (getters.searchTerm) {
                    commit("SET_FILTERED_RESULTS", getters.getResultsWithSearchTerm);
                } else {
                    commit("SET_FILTERED_RESULTS", state.availableResults.slice());
                }

                resolve();
            });
        }

        return new Promise((resolve) => {
            switch (state.filterMode) {
                case "OR":
                    dispatch("orFilterResults");
                    break;
                case "HYBRID":
                    dispatch("hybridFilterResults");
                    break;
                default:
                    dispatch("andFilterResults");
            }

            resolve();
        });
    },

    sortFilterResultsByValue({ state, commit }: ActionContext<FilterState, FilterState>, sortingValue: number) {
        commit("SET_FILTERED_RESULTS", sortResults({ filterResults: state.filteredResults.slice(), sortingValue }));
    },

    orderFilterResults({ state, commit }: ActionContext<FilterState, FilterState>) {
        commit("SET_FILTERED_RESULTS", [...state.filteredResults].reverse());
    },

    setupFilter(
        { commit }: ActionContext<FilterState, FilterState>,
        { activeFilters, searchTerm, availableResults, availableFilters }: any
    ) {
        if (!availableResults) return;

        return new Promise((resolve) => {
            const results = availableResults.slice();
            const filters = Object.assign({}, availableFilters);

            commit("SET_AVAILABLE_RESULTS", results);
            commit("SET_AVAILABLE_FILTER_GROUPS", filters);
            commit("SET_SEARCH_TERM", searchTerm);
            commit("SET_ACTIVE_FILTERS", activeFilters);

            resolve(true);
        });
    },

    async initFilter(
        { dispatch }: ActionContext<FilterState, FilterState>,
        { activeFilters, searchTerm, availableResults, availableFilters }: any
    ) {
        await dispatch("setupFilter", { activeFilters, searchTerm, availableResults, availableFilters });
        await dispatch("sortAvailableResultsIntoGroups");
        await dispatch("filterResults");
    },

    increaseLoadedFilterTeasers({ state, commit }: ActionContext<FilterState, FilterState>) {
        commit("SET_LOADED_FILTER_TEASERS", state.loadedFilterTeasers + 1);
        commit("SET_FILTER_TEASERS_LOADED", state.filterTeaserCounts === state.loadedFilterTeasers);
    },

    updateActiveFilters({ state, commit }: ActionContext<FilterState, FilterState>, { searchTerm }: any) {
        const activeFilters = state.activeFilters.slice();
        const searchTermIndex = activeFilters.indexOf(searchTerm);

        if (searchTermIndex > -1) {
            activeFilters.splice(searchTermIndex, 1);
        } else {
            activeFilters.push(searchTerm);
        }

        commit("SET_ACTIVE_FILTERS", activeFilters);
    },

    clearFilters({ commit, getters, dispatch }: ActionContext<FilterState, FilterState>) {
        commit("SET_ACTIVE_FILTERS", []);
        commit("SET_SEARCH_TERM", "");
        commit("SET_TOTAL_RESULT_GROUPS", getResultGroups(getters.getAvailableFilterGroups));
    },

    clearGroupFilters({ state, getters, commit }: ActionContext<FilterState, FilterState>, groupId: string) {
        let activeFilters = state.activeFilters.slice();

        const groupFilters = getters.getFilterSectionByGroup(groupId).groups.find((group: any) => {
            return group.id === groupId;
        }).filters;

        const groupFilterValues = groupFilters.map((groupFilter: any) => {
            return groupFilter.value;
        });

        activeFilters = activeFilters.filter((filter: string) => {
            return groupFilterValues.indexOf(filter) < 0;
        });

        commit("SET_ACTIVE_FILTERS", activeFilters);
    },

    openMobileFilters({ commit }: ActionContext<FilterState, FilterState>, { isOpen, filterGroup = "" }: any) {
        if (filterGroup.length) {
            commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", filterGroup);
        }

        commit("SET_MOBILE_FILTERS_OPEN", isOpen);
    },

    setMobileFiltersGroupScrollPosition(
        { state, commit }: ActionContext<FilterState, FilterState>,
        { groupId, scrollPosition }: any
    ) {
        const scrollPositions = Object.assign({}, state.mobileFiltersGroupScrollPositions);
        scrollPositions[groupId] = scrollPosition;

        commit("SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS", scrollPositions);
    },

    closeMobileFilters({ commit }: ActionContext<FilterState, FilterState>) {
        commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", "");
        commit("SET_MOBILE_FILTERS_OPEN", false);
    },

    sortResults({ state, commit, dispatch }: ActionContext<FilterState, FilterState>, sortingValue: number) {
        commit("SET_SORTING_VALUE", sortingValue);
        dispatch("sortFilterResultsByValue", state.SORTING_MAP[sortingValue]);
    },

    orderResults({ state, commit, dispatch }: ActionContext<FilterState, FilterState>, orderValue: number) {
        commit("SET_ORDER_VALUE", orderValue);
        dispatch("orderFilterResults");
    },

    setFilterMode({ commit }: ActionContext<FilterState, FilterState>, filterMode: string) {
        commit("SET_FILTER_MODE", filterMode);
    },

    setRecordingFilter({ commit }: ActionContext<FilterState, FilterState>, filterTopic: string) {
        commit("SET_RECORDING_FILTERS", filterTopic);
    },

    clearRecordingFilter({ commit }: ActionContext<FilterState, FilterState>, filters: string[]) {
        commit("CLEAR_RECORDING_FILTERS", filters);
    },
};
