import axios from "axios";
import {
    State,
    Hit,
    Config,
    CategoryGroups,
    CategoryAggregations,
    TimeRangeAggregations,
    Category,
    FilterGroup,
    Params,
    AppSettings,
    SortingOptions,
    SortingOptionsMap,
    Filter,
    APIDefinition,
} from "./types";

import { API_ROUTES } from "./constants";
import { requestParams, transformParams } from "../../helpers/transformParams";
import { updateCategory } from "../../helpers/updateCategory";
import { ActionContext, Getter, GetterTree } from "vuex";
import { Nullable } from "../../../js/_helpers/types";
import { MobileFilterScrollPositions } from "../../../store/modules/filterStore/types";

const sortingOptionsMap: SortingOptionsMap = {
    2: "prevalence",
    3: "date",
    4: "type",
};

export const namespaced = true;

export const state: State = {
    payload: {
        langId: 2,
        siteId: 200001,
        targetgroupId: 2,
        categories: "",
        days: 0,
        from: 0,
        order: "prevalence",
        size: 60,
        term: "",
    },

    hits: [],
    hitsCount: 0,

    categoryGroups: [],
    activeCategories: [],
    categoryAggregations: [],
    timeRangeAggregations: null,
    informationCategories: [],

    timeRanges: null,
    activeTimeRange: "",

    appSettings: null,

    sortingOptions: {},
    currentOrder: "",

    prodKeyWords: "",
    searchTerm: "",
    dokuSearchResults: "",

    searchIsTriggered: false,

    loading: false,
    loadingHits: false,
    loadingProdKeywords: false,
    loadingDocHits: false,
    loadingCategories: false,
    loadingAppSettings: false,

    config: null,
    defaultConfig: {
        searchDelay: 0,
        langId: 2,
        siteId: 200001,
        targetgroupId: 2,
        size: 60,
        lantarchapath: "de/gmb/",
        categoriesExclude: [],
        categoriesOrder: [],
        categoriesFixed: [],
        defaultOrder: "prevalence",
    },

    api: API_ROUTES,

    mobileFiltersOpen: false,
    mobileFiltersOpenedWithGroup: "",
    mobileFiltersGroupScrollPositions: {},

    loadMoreScrollTop: 0,
    loadMoreResultsHeight: 0,
};

export const getters = {
    payload: (state: State, getters: any): requestParams => {
        const config = !state.config ? state.defaultConfig : { ...state.defaultConfig, ...state.config };

        const payload = {
            ...state.payload,
            days: getters.payloadDays,
            term: state.searchTerm,
            categories: getters.categories,
            langId: config.langId,
            siteId: config.siteId,
            targetgroupId: config.targetgroupId,
            size: config.size,
            order: getters.currentOrder,
        };

        return transformParams(payload);
    },

    payloadDays(state: State): number {
        return state.activeTimeRange ? parseInt(state.activeTimeRange, 10) : 0;
    },

    prodKeyWords: (state: State): string => {
        return state.prodKeyWords;
    },

    dokuSearchResults: (state: State): string => {
        return state.dokuSearchResults;
    },

    searchIsTriggered: (state: State): boolean => {
        return state.searchIsTriggered;
    },

    hits: (state: State): Hit[] => {
        return state.hits;
    },

    hitsCount: (state: State): number => {
        return state.hitsCount;
    },

    searchTerm: (state: State): string => {
        return state.searchTerm;
    },

    termIsEmpty: (state: State): boolean => {
        return !state.searchTerm || state.searchTerm.length === 0;
    },

    loading: (state: State): boolean => {
        return (
            state.loadingHits ||
            state.loadingDocHits ||
            state.loadingProdKeywords ||
            state.loadingCategories ||
            state.loadingAppSettings
        );
    },

    searchDelay: (state: State): number => {
        if (!state.config) return state.defaultConfig.searchDelay;

        return state.config.searchDelay;
    },

    config: (state: State): Nullable<Config> => {
        return state.config || state.defaultConfig;
    },

    api: (state: State): APIDefinition => {
        return state.api;
    },

    categoriesExclude: (state: State): number[] => {
        if (!state.config) return state.defaultConfig.categoriesExclude;

        return state.config.categoriesExclude;
    },

    categoriesFixed: (state: State): number[] => {
        if (!state.config) return state.defaultConfig.categoriesFixed;

        return state.config.categoriesFixed;
    },

    categories: (state: State, getters: any): string => {
        const categories = [...getters.categoriesFixed, getters.activeCategories];

        return categories.join(",");
    },

    categoryGroups: (state: State): CategoryGroups => {
        return state.categoryGroups;
    },

    getAvailableCategories: (state: State, getters: any): string[] => {
        const availableCategories: string[] = [];

        getters.categoryGroups.forEach((categoryGroup: Category) => {
            categoryGroup.children.forEach((category: Category) => {
                availableCategories.push(category.category_id);
            });
        });

        return availableCategories;
    },

    categoryAggregations: (state: State): CategoryAggregations => {
        return state.categoryAggregations;
    },

    timeRangeAggregations: (state: State): Nullable<TimeRangeAggregations> => {
        return state.timeRangeAggregations;
    },

    informationCategories: (state: State): Nullable<Category> => {
        if (!state.informationCategories.length) return null;

        return state.informationCategories[0];
    },

    timeRanges: (state: State): Nullable<FilterGroup> => {
        if (!state.timeRanges) return null;

        return state.timeRanges;
    },

    getCategoryResultCount:
        (state: State) =>
        (categoryId: string): number => {
            const category = state.categoryAggregations.find((aggregation) => {
                return aggregation.key === categoryId;
            });

            if (!category) return 0;

            return parseInt(category.doc_count, 10);
        },

    getCategoryGroupById:
        (state: State) =>
        (groupId: string): Category | void => {
            if (!groupId) return;

            return state.categoryGroups.find((group) => {
                return group.category_id === groupId;
            });
        },

    getSelectedCategoriesInGroupCount: (state: State, getters: any) => (groupId: string) => {
        const currentGroup = getters.getCategoryGroupById(groupId);

        if (!currentGroup) return 0;

        return currentGroup.children.filter((category: Category) => {
            return state.activeCategories.includes(category.category_id);
        }).length;
    },

    activeCategories: (state: State): string[] => {
        return state.activeCategories;
    },

    validTimeRange: (state: State): boolean => {
        if (!state.timeRanges || !state.activeTimeRange) return false;

        return !!state.timeRanges.options.filter((option) => {
            return option.value === state.activeTimeRange;
        }).length;
    },

    activeTimeRange: (state: State): string => {
        return state.activeTimeRange;
    },

    activeTimeRangeFilter: (state: State): Filter | undefined => {
        if (!state.timeRanges || !state.activeTimeRange) return;

        return state.timeRanges.options.filter((timeRange: Filter) => {
            return timeRange.value === state.activeTimeRange;
        })[0];
    },

    getChildCategoryNameById:
        (state: State) =>
        (categoryId: string): string => {
            let categoryName = "";

            state.categoryGroups.forEach((categoryGroup) => {
                const category = categoryGroup.children.find((group) => {
                    return group.category_id === categoryId;
                });

                if (!category) return;

                categoryName = category.title;
            });

            return categoryName;
        },

    getMobileFiltersOpenedWithGroup: (state: State) => {
        return state.mobileFiltersOpenedWithGroup;
    },

    getMobileFiltersOpen: (state: State) => {
        return state.mobileFiltersOpen;
    },

    getMobileFiltersGroupScrollPositions: (state: State) => {
        return state.mobileFiltersGroupScrollPositions;
    },

    getMobileFiltersGroupScrollPosition: (state: State) => (groupId: string) => {
        if (groupId in state.mobileFiltersGroupScrollPositions) {
            return state.mobileFiltersGroupScrollPositions[groupId];
        } else {
            return false;
        }
    },

    getPageSize: (state: State) => {
        if (!state.config) return state.defaultConfig.size;

        return state.config.size;
    },

    getLoadMoreScrollTop: (state: State) => {
        return state.loadMoreScrollTop;
    },

    getLoadMoreResultsHeight: (state: State) => {
        return state.loadMoreResultsHeight;
    },

    getAppSettings: (state: State) => {
        return state.appSettings;
    },

    sortingOptions: (state: State, getters: any): Nullable<SortingOptions> => {
        const sortingOptions: SortingOptions = {};

        if (!getters.getAppSettings) return null;

        const settings = getters.getAppSettings.i18n;

        Object.keys(sortingOptionsMap).forEach((sortingOption) => {
            if (!(sortingOption in settings)) return;

            sortingOptions[settings[sortingOption]] = sortingOptionsMap[parseInt(sortingOption, 10)];
        });

        return sortingOptions;
    },

    customTypeLabels: (state: State, getters: any): string[] => {
        if (!getters.getAppSettings) return [];

        const settings = getters.getAppSettings.i18n;

        return settings.types;
    },

    currentOrder: (state: State) => {
        if (!state.currentOrder) return state.defaultConfig.defaultOrder;

        return state.currentOrder;
    },
};

export const mutations = {
    SET_HITS(state: State, hits: Hit[]): void {
        state.hits = hits;
    },

    INCREASE_HITS(state: State, hits: Hit[]): void {
        const increasedHits = [...state.hits, ...hits];

        state.hits = increasedHits;
    },

    SET_HITS_COUNT(state: State, hitsCount: number): void {
        state.hitsCount = hitsCount;
    },

    SET_HITS_LOADING(state: State, loading: boolean): void {
        state.loadingHits = loading;
    },

    SET_PROD_KEYWORDS_LOADING(state: State, loading: boolean): void {
        state.loadingProdKeywords = loading;
    },

    SET_DOC_HITS_LOADING(state: State, loading: boolean): void {
        state.loadingDocHits = loading;
    },

    SET_CATEGORIES_LOADING(state: State, loading: boolean): void {
        state.loadingCategories = loading;
    },

    SET_APP_SETTINGS_LOADING(state: State, loading: boolean): void {
        state.loadingAppSettings = loading;
    },

    SET_PROD_KEYWORDS(state: State, prodKeyWords: string): void {
        state.prodKeyWords = prodKeyWords;
    },

    SET_DOKU_SEARCH_RESULTS(state: State, dokuSearchResults: string): void {
        state.dokuSearchResults = dokuSearchResults;
    },

    SET_CATEGORIES_RESULT(state: State, categories: CategoryGroups): void {
        state.categoryGroups = categories;
    },

    SET_SEARCH_TRIGGERED(state: State, searchIsTriggered: boolean): void {
        state.searchIsTriggered = searchIsTriggered;
    },

    SET_CATEGORY_AGGREGATIONS(state: State, categoryAggregations: CategoryAggregations): void {
        state.categoryAggregations = categoryAggregations;
    },

    SET_TIME_RANGE_AGGREGATIONS(state: State, timeRangeAggregations: TimeRangeAggregations): void {
        state.timeRangeAggregations = timeRangeAggregations;
    },

    SET_INFORMATION_CATEGORIES(state: State, informationCategories: CategoryGroups): void {
        state.informationCategories = informationCategories;
    },

    SET_TIME_RANGES(state: State): void {
        if (!state.appSettings || !("i18n" in state.appSettings)) return;

        const filterGroupTimeRanges = state.appSettings.i18n.filters.days;

        state.timeRanges = Object.assign({ name: "days" }, filterGroupTimeRanges);
    },

    SET_ACTIVE_CATEGORIES(state: State, activeCategories: string[]): void {
        state.activeCategories = activeCategories;
    },

    SET_ACTIVE_TIME_RANGE(state: State, activeTimeRange: string): void {
        state.activeTimeRange = activeTimeRange;
    },

    SET_SEARCH_TERM(state: State, searchTerm: string): void {
        state.searchTerm = searchTerm;
    },

    SET_CONFIG(state: State, config: Nullable<Config>): void {
        state.config = config;
    },

    SET_API(state: State, api: APIDefinition): void {
        state.api = api;
    },

    INCREASE_PAGE_SIZE(state: State, pageSize: number): void {
        if (!state.config) return;

        state.config.size += pageSize;
    },

    SET_MOBILE_FILTERS_OPEN(state: State, isOpen: boolean) {
        state.mobileFiltersOpen = isOpen;
    },

    SET_MOBILE_FILTERS_OPEN_WITH_GROUP(state: State, categoryGroup: string) {
        state.mobileFiltersOpenedWithGroup = categoryGroup;
    },

    SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS(state: State, scrollPositions: MobileFilterScrollPositions) {
        state.mobileFiltersGroupScrollPositions = scrollPositions;
    },

    SET_LOAD_MORE_SCROLL_TOP(state: State, scrollTop: number) {
        state.loadMoreScrollTop = scrollTop;
    },

    SET_LOAD_MORE_RESULTS_HEIGHT(state: State, resultsHeight: number) {
        state.loadMoreResultsHeight = resultsHeight;
    },

    SET_APP_SETTINGS(state: State, appSettings: AppSettings) {
        state.appSettings = appSettings;
    },

    SET_ORDER(state: State, order: string) {
        state.currentOrder = order;
    },
};

export const actions = {
    async loadHits({ commit, getters }: ActionContext<State, State>, params: Params) {
        try {
            const { data } = await axios.post(`${state.api.search}`, { ...getters.payload, params });
            const hitResults = data.result.hits.hits;

            commit("SET_HITS", hitResults);
            commit("SET_HITS_COUNT", parseInt(data.result.hits.total, 10));

            commit("SET_CATEGORY_AGGREGATIONS", data.result.aggregations.categories.buckets);
            commit("SET_TIME_RANGE_AGGREGATIONS", data.result.aggregations.range.buckets);

            commit("SET_HITS_LOADING", false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error("error search", error);
            commit("SET_HITS_LOADING", false);
        }
    },

    async loadSearchResults({ commit, dispatch }: ActionContext<State, State>) {
        const params = { _action: "search.api.search" };

        commit("SET_HITS_LOADING", true);

        await dispatch("loadHits", params);
    },

    async loadMoreSearchResults({ commit, getters, dispatch }: ActionContext<State, State>) {
        const params = { _action: "search.api.search" };

        commit("SET_HITS_LOADING", true);
        commit("INCREASE_PAGE_SIZE", getters.getPageSize);

        await dispatch("loadHits", params);
    },

    async loadSearchBanner({ commit, getters }: ActionContext<State, State>) {
        const params = {
            langId: getters.config.langId,
            lantarchapath: getters.config.lantarchapath,
            searchkey: getters.searchTerm,
        };

        commit("SET_PROD_KEYWORDS_LOADING", true);

        try {
            const { data } = await axios.get(`${state.api.services}${state.api.searchBanners}`, {
                params: transformParams(params),
            });

            commit("SET_PROD_KEYWORDS", data);
            commit("SET_PROD_KEYWORDS_LOADING", false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit("SET_PROD_KEYWORDS_LOADING", false);
        }
    },

    async loadSearchDokuResults({ commit, getters }: ActionContext<State, State>) {
        const params = { langId: getters.config.langId, searchkey: getters.searchTerm };

        commit("SET_DOC_HITS_LOADING", true);

        try {
            const { data } = await axios.get(`${state.api.services}${state.api.dokuResults}`, {
                params: transformParams(params),
            });

            commit("SET_DOKU_SEARCH_RESULTS", data);
            commit("SET_DOC_HITS_LOADING", false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit("SET_DOC_HITS_LOADING", false);
        }
    },

    async loadCategories({ commit, getters }: ActionContext<State, State>) {
        const params = {
            langId: getters.config.langId,
            categoriesExclude: getters.categoriesExclude.join(","),
            categoriesOrder: getters.config.categoriesOrder.join(","),
        };

        commit("SET_CATEGORIES_LOADING", true);

        try {
            const { data } = await axios.post(`${state.api.categories}`, {
                ...transformParams(params),
            });

            const informationCategories = data.result.filter((categoryGroup: Category) => {
                return categoryGroup.category_id === "394";
            });

            commit("SET_CATEGORIES_RESULT", data.result);
            commit("SET_INFORMATION_CATEGORIES", informationCategories);
            commit("SET_CATEGORIES_LOADING", false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit("SET_CATEGORIES_LOADING", false);
        }
    },

    async loadAppSettings({ commit, getters }: ActionContext<State, State>) {
        const params = {
            langId: getters.config.langId,
        };

        commit("SET_APP_SETTINGS_LOADING", true);

        try {
            const { data } = await axios.post(`${state.api.appSettings}`, {
                ...transformParams(params),
            });

            commit("SET_APP_SETTINGS", data.result);
            commit("SET_TIME_RANGES");
            commit("SET_APP_SETTINGS_LOADING", false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            commit("SET_APP_SETTINGS_LOADING", false);
        }
    },

    loadAllResults({ dispatch }: ActionContext<State, State>) {
        dispatch("loadSearchResults");
        dispatch("loadSearchBanner");
        dispatch("loadSearchDokuResults");
    },

    loadEverything({ dispatch }: ActionContext<State, State>) {
        dispatch("loadAppSettings");
        dispatch("loadCategories");
        dispatch("loadSearchResults");
        dispatch("loadSearchBanner");
        dispatch("loadSearchDokuResults");
    },

    loadSupportEverything({ dispatch }: ActionContext<State, State>) {
        dispatch("loadAppSettings");
        dispatch("loadCategories");
        dispatch("loadSearchResults");
    },

    loadSupportResults({ dispatch }: ActionContext<State, State>) {
        dispatch("loadSearchResults");
    },

    updateSearchTerm({ commit }: ActionContext<State, State>, searchTerm: string) {
        commit("SET_SEARCH_TERM", searchTerm);
    },

    setupFilter({ commit }: ActionContext<State, State>, config: Nullable<Config>) {
        commit("SET_CONFIG", config);
    },

    updateActiveCategories({ state, commit }: ActionContext<State, State>, category: string) {
        if (!category) return;

        let activeCategories = [...state.activeCategories];
        const categories = category.split(",");

        categories.forEach((category) => {
            activeCategories = updateCategory({ activeCategories, category });
        });

        commit("SET_ACTIVE_CATEGORIES", activeCategories);
    },

    updateActiveTimeRange({ state, commit }: ActionContext<State, State>, timeRange: string) {
        if (!timeRange) return;

        if (timeRange === state.activeTimeRange) {
            commit("SET_ACTIVE_TIME_RANGE", "");
        } else {
            commit("SET_ACTIVE_TIME_RANGE", timeRange);
        }
    },

    clearCategoryGroupFilters({ state, getters, commit }: ActionContext<State, State>, category: number) {
        let activeCategories = [...state.activeCategories];
        const categoryGroup = getters.getCategoryGroupById(category);

        const groupFilterValues = categoryGroup.children.map((category: any) => {
            return category.category_id;
        });

        activeCategories = activeCategories.filter((category: string) => {
            return groupFilterValues.indexOf(category) < 0;
        });

        commit("SET_ACTIVE_CATEGORIES", activeCategories);
    },

    clearCategories({ commit, getters }: ActionContext<State, State>) {
        commit("SET_ACTIVE_CATEGORIES", []);
        commit("SET_ACTIVE_TIME_RANGE", "");
    },

    clearSearchTerm({ commit, getters }: ActionContext<State, State>) {
        commit("SET_SEARCH_TERM", "");
    },

    clearTimeRange({ commit, getters }: ActionContext<State, State>) {
        commit("SET_ACTIVE_TIME_RANGE", "");
    },

    resetSearch({ dispatch }: ActionContext<State, State>) {
        dispatch("clearCategories");
        dispatch("clearSearchTerm");
    },

    openMobileFilters({ commit }: any, { isOpen, categoryGroup = "" }: any) {
        if (categoryGroup.length) {
            commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", categoryGroup);
        }

        commit("SET_MOBILE_FILTERS_OPEN", isOpen);
    },

    closeMobileFilters({ commit }: any) {
        commit("SET_MOBILE_FILTERS_OPEN_WITH_GROUP", "");
        commit("SET_MOBILE_FILTERS_OPEN", false);
    },

    setMobileFiltersGroupScrollPosition({ state, commit }: any, { groupId, scrollPosition }: any) {
        const scrollPositions = Object.assign({}, state.mobileFiltersGroupScrollPositions);
        scrollPositions[groupId] = scrollPosition;

        commit("SET_MOBILE_FILTERS_GROUP_SCROLL_POSITIONS", scrollPositions);
    },

    setLoadMoreScrollTop({ commit }: any, scrollTop: number) {
        if (!scrollTop) return;

        commit("SET_LOAD_MORE_SCROLL_TOP", scrollTop);
    },

    setLoadMoreResultsHeight({ commit }: any, resultsHeight: number) {
        if (!resultsHeight) return;

        commit("SET_LOAD_MORE_RESULTS_HEIGHT", resultsHeight);
    },

    setOrder({ commit }: any, order: string) {
        if (!order) return;

        commit("SET_ORDER", order);
    },

    setSearchIsTriggered({ commit }: any, searchIsTriggered: boolean) {
        commit("SET_SEARCH_TRIGGERED", searchIsTriggered);
    },

    updateAPI({ commit, getters }: any, apiDefinition: APIDefinition | undefined) {
        //Use default APIDefinition
        if (!apiDefinition) return;

        const api = { ...getters.api, ...apiDefinition };

        commit("SET_API", api);
    },
};
