import { union } from "lodash";

import { ResultGroups, ResultItem, Results } from "./types";
import { Filter, FilterGroup, FilterSection } from "../../../components/organisms/filter-multi/types";
import { FilterState } from "./state";
import { getResultCountForSingleFilter } from "../../helpers/getResultCountForSingleFilter";
import { copyFilterSections } from "../../helpers/copyFilterSections";
import getResultGroupsUnion from "../../helpers/getResultGroupsUnion";

export const getters: any = {
    searchTerm: (state: FilterState) => {
        return state.searchTerm;
    },

    termIsEmpty: (state: FilterState): boolean => {
        return state.searchTerm.length === 0;
    },

    getFilterInitialized: (state: FilterState) => {
        return state.filterInitialized;
    },

    getFilterTeaserCounts: (state: FilterState) => {
        return state.filterTeaserCounts;
    },

    getFilterTeasersLoaded: (state: FilterState) => {
        return state.filterTeasersLoaded;
    },

    getLoadedFilterTeasers: (state: FilterState) => {
        return state.loadedFilterTeasers;
    },

    getAvailableResults: (state: FilterState) => {
        return state.availableResults;
    },

    getAvailableResultsCount: (state: FilterState) => {
        return state.availableResults.length;
    },

    getFilteredResults: (state: FilterState) => {
        return state.filteredResults;
    },

    getFilteredResultsCount: (state: FilterState) => {
        if (!state.filteredResults) return 0;

        return state.filteredResults.length;
    },

    getFilteredGroupResults: (state: FilterState) => {
        return state.filteredGroupResults;
    },

    getTotalResultGroups: (state: FilterState): ResultGroups => {
        return state.totalResultGroups;
    },

    getAvailableResultGroups: (state: FilterState): ResultGroups => {
        return state.availableResultGroups;
    },

    getGroupsWithResultsCount: (state: FilterState) => {
        const groups = Object.values(state.totalResultGroups).map((group) => {
            return group.length > 0 ? 1 : 0;
        }) as number[];

        return groups.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },

    getAvailableFilterGroups: (state: FilterState) => {
        return state.availableFilterGroups;
    },

    getSortedAvailableFilterGroups: (state: FilterState, getters: any) => {
        const filterSectionsWithSortedFilterGroups = copyFilterSections(getters.getAvailableFilterGroups);

        Object.values(filterSectionsWithSortedFilterGroups).forEach((section) => {
            section.groups.forEach((group) => {
                group.filters.sort((filterA, filterB) => {
                    const resultCountA = getters.getHybridResultCountForSingleFilter(filterA.value);
                    const resultCountB = getters.getHybridResultCountForSingleFilter(filterB.value);

                    if (resultCountA === resultCountB) return 0;

                    return resultCountA < resultCountB ? 1 : -1;
                });
            });
        });

        return filterSectionsWithSortedFilterGroups;
    },

    getActiveFilters: (state: FilterState) => {
        return state.activeFilters;
    },

    getFilterGroupsFromResultItem: (state: FilterState) => (resultItem: ResultItem) => {
        let filterGroups: (false | string)[] = [];
        const filters = Object.values(state.availableFilterGroups)[0];

        resultItem.filterterms.forEach((filterTerm: string) => {
            filterGroups = filters.groups
                .map((filterGroup: FilterGroup) => {
                    const hasFilterTerm = filterGroup.filters.some((filter) => {
                        return filter.value === filterTerm;
                    });

                    if (hasFilterTerm && filterGroups.indexOf(filterGroup.id) < 0) {
                        return filterGroup.id;
                    } else {
                        return false;
                    }
                })
                .concat(filterGroups)
                .filter((filterId: string | boolean) => {
                    return typeof filterId === "string";
                });
        });

        return filterGroups;
    },

    getFilterTermFromFilterGroupId: (state: FilterState) => (filterGroupId: string, filterTerms: string[]) => {
        if (!filterTerms) return;

        const filters = Object.values(state.availableFilterGroups)[0];

        const filterGroup = filters.groups.find((group: FilterGroup) => {
            return group.id === filterGroupId;
        });

        if (!filterGroup) return;

        return filterTerms.filter((filterTerm) => {
            const filteredTerms = filterGroup.filters.filter((filter: Filter) => {
                return filter.value === filterTerm;
            });

            if (!filteredTerms.length) return false;

            return filteredTerms.filter((filter: Filter) => {
                return filter.value === filterTerm;
            });
        });
    },

    getSectionFromFilterTerm: (state: FilterState) => (filterTerm: string) => {
        return Object.values(state.availableFilterGroups).find((filterSection) => {
            return filterSection.groups.find((group: FilterGroup) => {
                return group.filters.find((filter) => {
                    return filter.value === filterTerm;
                });
            });
        });
    },

    getFilterName: (state: FilterState, getters: any) => (filterTerm: string) => {
        const filterSection = getters.getSectionFromFilterTerm(filterTerm);

        if (!filterSection) return;

        return filterSection.groups
            .find((group: FilterGroup) => {
                return group.filters.find((filter) => {
                    return filter.value === filterTerm;
                });
            })
            .filters.find((filter: Filter) => {
                return filter.value === filterTerm;
            }).name;
    },

    getFilterNames: (state: FilterState, getters: any) => (filterTerms: string[]) => {
        const filterNames: string[] = [];

        filterTerms.forEach((filterTerm) => {
            filterNames.push(getters.getFilterName(filterTerm));
        });

        return filterNames.join(", ");
    },

    getGroupFromFilterTerm: (state: FilterState, getters: any) => (filterTerm: String) => {
        if (!getters.getSectionFromFilterTerm(filterTerm)) return null;

        return getters.getSectionFromFilterTerm(filterTerm).groups.find((group: FilterGroup) => {
            return group.filters.find((filter) => {
                return filter.value === filterTerm;
            });
        });
    },

    getGroupIdFromFilterTerm: (state: FilterState, getters: any) => (filterTerm: string) => {
        if (!getters.getGroupFromFilterTerm(filterTerm)) return null;

        return getters.getGroupFromFilterTerm(filterTerm).id;
    },

    loading: (state: FilterState) => {
        return state.loading;
    },

    getIntersectionFromResultGroups(state: FilterState, getters: any) {
        const resultGroups = [...Object.values(getters.getTotalResultGroups)] as Results[];

        return getResultGroupsUnion(resultGroups);
    },

    hasSearchTermInText:
        (state: FilterState, getters: any) =>
        (text: string | undefined): boolean => {
            if (!text) return false;

            return text.toUpperCase().indexOf(getters.searchTerm.toUpperCase()) > -1;
        },

    getResultsFilteredBySearchTerm: (state: FilterState, getters: any) => (results: ResultItem[]) => {
        const titleResults = results.filter((result: ResultItem) => {
            return getters.hasSearchTermInText(result.title);
        });
        const descriptionResults = results.filter((result: ResultItem) => {
            return getters.hasSearchTermInText(result.description);
        });
        const textResults = results.filter((result: ResultItem) => {
            return getters.hasSearchTermInText(result.text);
        });

        return union(titleResults, descriptionResults, textResults);
    },

    getResultsWithSearchTerm(state: FilterState, getters: any): ResultItem[] {
        let results;

        if (!getters.getActiveFilters.length) {
            results = getters.getAvailableResults;
        } else {
            results = getters.getIntersectionFromResultGroups;
        }

        return getters.getResultsFilteredBySearchTerm(results);
    },

    getFilterSectionByGroup: (state: FilterState) => (groupId: string) => {
        return Object.values(state.availableFilterGroups).find((filterSection) => {
            return filterSection.groups.find((group: any) => {
                return group.id === groupId;
            });
        });
    },

    getSelectedFiltersInGroupCount: (state: FilterState, getters: any) => (groupId: number) => {
        const currentSection = getters.getFilterSectionByGroup(groupId);

        const currentGroup = currentSection.groups.find((group: any) => {
            return group.id === groupId;
        });

        if (currentGroup) {
            return currentGroup.filters.filter((filter: any) => {
                return state.activeFilters
                    .map((activeFilter: any) => {
                        return activeFilter === filter.value;
                    })
                    .some((active: boolean) => {
                        return active;
                    });
            }).length;
        } else {
            return 0;
        }
    },

    getSelectedFiltersInSectionCount: (state: FilterState, getters: any) => (section: any) => {
        let count = 0;

        section.groups.forEach((group: any) => {
            count += getters.getSelectedFiltersInGroupCount(group.id);
        });

        return count;
    },

    getFilterGroupFromSectionByGroupId: () => (filterSection: FilterSection, groupId: string) => {
        return filterSection.groups.find((group) => {
            return group.id === groupId;
        });
    },

    getFilterSectionByGroupId: (state: FilterState, getters: any) => (groupId: string) => {
        return Object.values(state.availableFilterGroups).find((section) => {
            const filterGroup = getters.getFilterGroupFromSectionByGroupId(section, groupId);

            if (!filterGroup) return false;

            return filterGroup.length > 0;
        });
    },

    getGroupResultCounts: (state: FilterState, getters: any) => async (groupId: string) => {
        let count = 0;

        const filterSection = await getters.getFilterSectionByGroupId(groupId);

        if (!filterSection) return count;

        const filterGroup = getters.getFilterGroupFromSectionByGroupId(filterSection, groupId);

        filterGroup.filters.forEach((filter: Filter) => {
            count += getters.getSingleFilterResultCounts(filter.value);
        });

        return count;
    },

    getActiveFilterGroups: (state: FilterState, getters: any) => {
        const activeFilterGroups: string[] = [];

        getters.getActiveFilters.forEach((filter: string) => {
            activeFilterGroups.push(getters.getGroupIdFromFilterTerm(filter));
        });

        return activeFilterGroups;
    },

    getHybridResultCountForSingleFilter: (state: FilterState, getters: any) => (filterTerm: string) => {
        const groupId = getters.getGroupIdFromFilterTerm(filterTerm);

        if (
            getters.getFilteredResultsCount === getters.getAvailableResultsCount ||
            !getters.getFilteredGroupResults[groupId]
        ) {
            return getResultCountForSingleFilter({
                filterResults: getters.getAvailableResults,
                filterTerm,
            });
        }

        return getResultCountForSingleFilter({
            filterResults: getters.getFilteredGroupResults[groupId],
            filterTerm,
        });
    },

    getSingleFilterResultCounts: (state: FilterState, getters: any) => (filterTerm: string) => {
        let singleFilterResultCount = 0;

        switch (state.filterMode) {
            case "OR":
                singleFilterResultCount = getResultCountForSingleFilter({
                    filterResults: state.availableResults,
                    filterTerm,
                });
                break;
            case "HYBRID":
                singleFilterResultCount = getters.getHybridResultCountForSingleFilter(filterTerm);
                break;
            default:
                singleFilterResultCount = getResultCountForSingleFilter({
                    filterResults: state.filteredResults,
                    filterTerm,
                });
        }

        return singleFilterResultCount;
    },

    getMobileFiltersOpen: (state: FilterState) => {
        return state.mobileFiltersOpen;
    },

    getMobileFiltersOpenedWithGroup: (state: FilterState) => {
        return state.mobileFiltersOpenedWithGroup;
    },

    getMobileFiltersGroupScrollPositions: (state: FilterState) => {
        return state.mobileFiltersGroupScrollPositions;
    },

    getMobileFiltersGroupScrollPosition: (state: FilterState) => (groupId: string) => {
        if (groupId in state.mobileFiltersGroupScrollPositions) {
            return state.mobileFiltersGroupScrollPositions[groupId];
        } else {
            return false;
        }
    },

    getSortingValue: (state: FilterState) => {
        return state.sortingValue;
    },

    getOrderValue: (state: FilterState) => {
        return state.orderValue;
    },

    getFilterMode: (state: FilterState) => {
        return state.filterMode;
    },

    selectedRecordingFilters: (state: FilterState): string[] => {
        return state.selectedRecordingFilters;
    },
};
